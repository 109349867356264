<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="detail.name"
    class="px-5 py-3"
    v-if="detail"
  >
    <!--    <base-verification-action-button-->
    <!--      :permissions="permissions"-->
    <!--      :allowedActions="allowedActions"-->
    <!--      :basePath="basePath"-->
    <!--      :id="detail.id.toString()"-->
    <!--      v-if="detail.id"-->
    <!--      @acceptAction="acceptAction"-->
    <!--      @rejectAction="rejectAction"-->
    <!--      @pendingAction="pendingAction"-->
    <!--    >-->
    <!--    </base-verification-action-button>-->
    <v-row>
      <v-col cols="6" class="border--right">
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.name') }}
          </v-col>
          <v-col cols="6">
            {{ detail.name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.phone') }}
          </v-col>
          <v-col cols="6">
            {{ detail.phone_number }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('agentRegistration.email') }}
          </v-col>
          <v-col cols="6">
            {{ detail.email }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            {{ $t('referralCode.referralCode') }}
          </v-col>
          <v-col cols="6">
            {{ detail.referral_code }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('referralCode.pendingCount') }}
          </v-col>
          <v-col cols="6">
            {{ detail.pending_count }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            {{ $t('referralCode.unclaimedCount') }}
          </v-col>
          <v-col cols="6">
            {{ detail.unclaimed_count }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  computed: {
    ...mapState({
      basePath: (state) => state.referralCode.basePath,
    }),
  },
  methods: {
    // async acceptAction(id) {
    //   console.log('ACCEPT AGENT VERIFICATION ID: ', id);
    //   try {
    //     await this.$store.dispatch('agentVerification/accept', id);
    //     await this.$swal(
    //       this.$i18n.t('general.success'),
    //       this.$i18n.t('agentVerification.accept.successMsg'),
    //       'success',
    //     );
    //     this.$router.replace('/registration-management/agent-verifications');
    //     // eslint-disable-next-line no-empty
    //   } catch (e) {}
    // },
    // async rejectAction({ id: id, reason: reason }) {
    //   console.log('REJECT AGENT VERIFICATION ID: ', id);
    //   try {
    //     await this.$store.dispatch('agentVerification/reject', { id: id, reason: reason });
    //     await this.$swal(
    //       this.$i18n.t('general.success'),
    //       this.$i18n.t('agentVerification.reject.successMsg'),
    //       'success',
    //     );
    //     this.$router.replace('/registration-management/agent-verifications');
    //     // eslint-disable-next-line no-empty
    //   } catch (e) {}
    // },
    // async pendingAction(id) {
    //   console.log('PENDING AGENT VERIFICATION ID: ', id);
    //   try {
    //     await this.$store.dispatch('agentVerification/pending', id);
    //     await this.$swal(
    //       this.$i18n.t('general.success'),
    //       this.$i18n.t('agentVerification.pending.successMsg'),
    //       'success',
    //     );
    //     this.$router.replace('/registration-management/agent-verifications');
    //     // eslint-disable-next-line no-empty
    //   } catch (e) {}
    // },
  },
};
</script>
